/* global jsVars */
import ajax from '../helpers/ajax';

const btnNewness = document.querySelector('.newness-load-more');
const wrapperNewness = document.getElementById('home-newness');

window.addEventListener('load', function () {
	if (typeof btnNewness !== 'undefined' && btnNewness != null) {
		btnNewness.addEventListener('click', (e) => {
			e.preventDefault();
			const page = btnNewness.getAttribute('data-page');
			const formData = new FormData();

			formData.append('action', 'load_more_post_newness');
			formData.append('nonce', jsVars.nonce);
			formData.append('page', page);

			btnNewness.textContent = 'CARGANDO...';
			ajax({
				url: jsVars.ajax_url,
				method: 'POST',
				params: formData,
				async: true,
				done: function (response) {
					if (response.status) {
						wrapperNewness.innerHTML += response.html;
					}
					if (!response.enabled) {
						btnNewness.classList.add('hide');
					}
					btnNewness.dataset.page = response.page;
				},
				error: function (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				},
				always: function () {
					btnNewness.textContent = 'conoce más novedades';
				},
			});
		});
	}
});
